import * as React from 'react';
import CouponSection from '../../components/CouponSection';
import { Helmet } from 'react-helmet';
import { useLocaleString, useTexts } from '@devsisters/gatsby-plugin-i18n';
import useStaticAsset from '../../hooks/useStaticAsset';

const CouponPage: React.FC = () => {
  const locale = useLocaleString();
  const t = useTexts();
  const asset = useStaticAsset();
  const pathname = `www.cookierun.com/${locale}/coupon`;
  function normalizeLocale(locale: string) {
    if (/^zh[_-]Hant/.test(locale)) {
      return 'zh-TW';
    }
    return locale;
  }
  return (
    <>
      <Helmet>
        <title>{t`cp_title`}</title>
        <meta name="description" content={t`description`} />
        <meta property="og:url" content={pathname} />
        <meta
          property="og:image"
          content={asset`coupon/${locale}/banner.jpg`}
        />
        <meta property="og:site_name" content={t`cp_title`} />
        <meta property="og:description" content={t`description`} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="314" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content={pathname} />
        <meta name="twitter:title" content={t`cp_title`} />
        <meta name="twitter:description" content={t`description`} />
        <meta
          name="twitter:image"
          content={asset`homepage/opengraph/${locale}.jpg`}
        />
        {process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY
          ? <script src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}&hl=${normalizeLocale(locale) ?? 'en'}`}></script>
          : null}
      </Helmet>
      <CouponSection />
    </>
  );
};

export default CouponPage;
