import * as React from 'react';
import * as styles from './styles.module.scss';
import CouponForm from '../CouponForm';
import LanguageSelector from '../LanguageSelector';

interface CouponSection {}

const CouponSection: React.FC<CouponSection> = ({}) => {
  return (
    <div className={styles.CouponSection}>
      <div className={styles.inner}>
        <CouponForm />
        <LanguageSelector
          pathname="/coupon"
          withoutTopMargin
        />
      </div>
    </div>
  );
};

export default CouponSection;
