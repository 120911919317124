import * as React from 'react';
import { useLocaleString } from '@devsisters/gatsby-plugin-i18n';
import { navigate } from 'gatsby';
import classNames from 'classnames';

import * as styles from './styles.module.scss';
import localeNamesContext from '../../contexts/localeNames';

interface LanguageSelectorProps {
  pathname?: string;
  withoutTopMargin?: boolean;
  supportLanguages?: string[];
}
const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  pathname = '/',
  withoutTopMargin,
  supportLanguages = ['ko', 'ja', 'zh-Hant', 'th', 'en'],
}) => {
  const currentLocale = useLocaleString();
  const localeNames = React.useContext(localeNamesContext);
  const onChangeLocale = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const targetLocale = event.currentTarget.value;
      navigate(`/${targetLocale}${pathname}`, { replace: true });
    },
    [pathname]
  );

  return (
    <div
      className={classNames(
        styles.selectBoxContainer,
        withoutTopMargin && styles.withoutTopMargin
      )}
    >
      <img
        className={styles.langGlobe}
        src={require('../../images/homepage/footer/language_icon.png')}
      />
      <select
        className={styles.langSel}
        onChange={onChangeLocale}
        value={currentLocale}
      >
        {supportLanguages.map(locale => (
          <option key={locale} value={locale}>
            {localeNames[locale]}
          </option>
        ))}
      </select>
      <img
        className={styles.langArrow}
        src={require('../../images/homepage/footer/btn-lang-dropdown.png')}
      />
    </div>
  );
};

export default LanguageSelector;
